<template>
  <div>
    <div
      class="mb-4 body-1"
      >
      {{ text }}
    </div>

    <v-form                                                                                      
      ref="form"                                                                                 
      v-model="valid"                                                                            
      lazy-validation                                                                            
      >                                                                                          
      <v-text-field
        label="Nombre de la tienda"
        v-model="store.name"
        filled
        :rules="rules"
        ></v-text-field>
      <v-autocomplete
        v-model="store.informationAttributes.businessField"
        label="Rubro"
        :items="categories"
        item-text="name"
        item-value="name"
        placeholder="Ej: Farmacia, Alimentos y bebidas, Groceries"
        :rules="rules"
        filled></v-autocomplete>
    </v-form>                                                                                    

    <div
      class="caption mb-3"
      >
      La creación de tu e-commerce y la publicación de productos es totalmente gratuita. Se cobrará un porcentaje de comisión por venta efectuada, tal y como se estipula en los <terms></terms>.
    </div>

    <div
      class="text-right"
      >
      <v-btn
        :class="$vuetify.breakpoint.mdAndUp ? 'mr-1 grow' : 'mb-1'"
        class="py-5"
        outlined
        :block="$vuetify.breakpoint.mobile"
        color="primary"
        small
        @click="next"
        >
        Continuar
      </v-btn>
    </div>
  </div>
</template>

<script>
import { CreateStore } from '@/graphql/mutations/stores'
import { mapGetters } from 'vuex'
import { BusinessCategories } from '@/utils/business_category'

import Terms from '@/components/shared/Terms'

export default {
  data () {
    return {
      valid: true,
      categories: BusinessCategories,
      store: {
        name: null,
        informationAttributes: {
          businessField: null,
        }
      },
      rules: [
        v => !!v || 'Campo obligatorio.'
      ]
    }
  },

  props: {
    text: {
      required: false,
      type: String,
      default: 'Para finalizar y dirigirte al panel de administración, necesitamos saber un poco más sobre tu tienda.'
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    next () {
      this.valid = this.$refs.form.validate()

      if (this.valid) {
        this.$apollo.mutate({
          mutation: CreateStore,
          variables: {
            input: {
              attributes: {
                ...this.store,
              },
            }
          }
        }).then( res => {
          this.$emit('next')
        })
      }
    }
  },

  components: { Terms }
}
</script>
